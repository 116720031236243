<template>
  <div class="general-data-wrapper">
    <NbCard
      id="general-data-card"
      class="mb-2"
      :title="$t('companyPage.generalData')"
      :subTitle="$t('companyPage.generalDataPage.companySubTitle')"
    >
      <template #body>
        <div class="d-flex justify-content-start flex-wrap">
          <NbTextInput
            :required="true"
            id="name"
            class="w-32 mx-1 mb-3"
            :name="$t('companyPage.companyName')"
            :placeholder="$t('companyPage.companyName')"
            :error="errors['name']"
            @valid="updateValidFields"
            v-model="newCompany.name"
          />
          <NbTextInput
            :required="true"
            id="email"
            type="email"
            rules="email"
            class="w-32 mx-1 mb-3"
            :name="$t('email')"
            :placeholder="$t('email')"
            :error="errors['email']"
            @valid="updateValidFields"
            v-model="newCompany.email"
          />
          <NbTextInput
            :required="true"
            id="phone"
            type="phone"
            class="w-32 mx-1"
            :name="$t('phone')"
            :placeholder="'+00 0 0000 0000'"
            :error="errors['phone']"
            @valid="updateValidFields"
            v-model="newCompany.phone"
          />
          <NbTextInput
            disabled
            id="companyID"
            class="w-32 mx-1 mb-3 text-gray-60"
            :helpText="$t('companyPage.usedByNB')"
            :name="$t('companyPage.companyID')"
            :placeholder="$t('companyPage.companyID')"
            :error="errors['id']"
            v-model="newCompany.id"
          />
          <NbTextInput
            id="legalName"
            class="w-32 mx-1 mb-3"
            :name="$t('companyPage.legalName')"
            :placeholder="$t('companyPage.legalName')"
            :error="errors['legalName']"
            @valid="updateValidFields"
            v-model="newCompany.legal_name"
          />
          <NbTextInput
            id="rfb_code"
            class="w-32 mx-1 mb-3"
            :name="$t('companyPage.rfbCode')"
            :placeholder="$t('companyPage.rfbCode')"
            :error="errors['rfb_code']"
            v-model="newCompany.rfb_code"
          />
          <NbTextInput
            :required="true"
            id="site"
            class="w-48-5 mx-1 mb-3"
            :name="$t('companyPage.websiteURL')"
            :placeholder="$t('companyPage.websiteURL')"
            :error="errors['site']"
            @valid="updateValidFields"
            v-model="newCompany.site"
          />
          <NbTextInput
            :required="true"
            id="tax_number"
            class="w-48-5 mx-1"
            :name="$t('companyPage.taxNumber')"
            :placeholder="$t('companyPage.taxNumber')"
            :error="errors['tax_number']"
            @valid="updateValidFields"
            v-model="newCompany.tax_number"
          />
        </div>
        <div class="d-flex justify-content-end">
          <NbButton
            class="mt-2 mr-3"
            variant="secondary"
            size="sm"
            @click="saveChanges"
          >
            {{ $t("update") }}
          </NbButton>
        </div>
      </template>
    </NbCard>

    <NbCard
      id="company-addres-card"
      class="mb-2"
      :title="$t('companyPage.generalDataPage.companyAddress')"
      :subTitle="$t('companyPage.generalDataPage.companyAddressSubTitle')"
    >
      <template #body>
        <div class="d-flex justify-content-between flex-wrap">
          <NbSelectInput
            :required="true"
            id="country"
            class="w-32 mx-1 mb-3"
            :name="$t('country')"
            :placeholder="$t('country')"
            :options="countriesAll"
            :error="errors['country']"
            @valid="updateValidFields"
            v-model="newCompany.country"
          />
          <NbTextInput
            :required="true"
            id="zip_code"
            type="number"
            class="w-32 mx-1 mb-3"
            :name="$t('postalCode')"
            placeholder="12345-678"
            :error="errors['zip_code']"
            @valid="updateValidFields"
            v-model="newCompany.zip_code"
          />
          <NbTextInput
            :required="true"
            id="city"
            class="w-32 mx-1 mb-3"
            :name="$t('city')"
            :placeholder="$t('city')"
            :error="errors['city']"
            @valid="updateValidFields"
            v-model="newCompany.city"
          />
          <NbTextInput
            :required="true"
            id="state"
            class="w-32 mx-1 mb-3"
            :name="$t('state')"
            :placeholder="$t('state')"
            :error="errors['state']"
            @valid="updateValidFields"
            v-model="newCompany.state"
          />
          <NbTextInput
            :required="true"
            id="address"
            class="w-32 mx-1 mb-3"
            :name="$t('address')"
            :placeholder="$t('address')"
            :error="errors['address']"
            @valid="updateValidFields"
            v-model="newCompany.address"
          />
          <NbTextInput
            :required="true"
            id="address_number"
            type="number"
            class="w-32 mx-1 mb-3"
            :name="$t('number')"
            :placeholder="$t('number')"
            :error="errors['address_number']"
            @valid="updateValidFields"
            v-model="newCompany.address_number"
          />
          <NbTextInput
            :required="true"
            id="address_complement"
            class="w-100 mx-1 mb-3"
            :name="$t('companyPage.addressComplement')"
            :placeholder="$t('companyPage.addressComplement')"
            :error="errors['address_complement']"
            @valid="updateValidFields"
            v-model="newCompany.address_complement"
          />

          <NbSwitch
            id="use_seller_devolution_address"
            :name="$t('companyPage.devolutionAddress')"
            :disabled="user.platform === 'total'"
            @change="updateDevolutionAddressSwitch()"
            v-model="newCompany.use_seller_devolution_address"
          >
            {{ $t("companyPage.ownDevolutionAddress") }}
          </NbSwitch>
        </div>
        <div class="d-flex justify-content-end">
          <NbButton
            class="mt-2 mr-3"
            variant="secondary"
            size="sm"
            @click="saveChanges"
          >
            {{ $t("update") }}
          </NbButton>
        </div>
      </template>
    </NbCard>

    <NbCard
      id="devolution-addres-card"
      :title="$t('companyPage.devolutionAddress')"
      class="transition"
      :class="{ 'bg-gray-05': newCompany.use_seller_devolution_address }"
      :subTitle="$t('companyPage.devolutionAddressSubtitle')"
    >
      <template #body>
        <div class="d-flex justify-content-between flex-wrap">
          <NbTextInput
            :required="true"
            id="devolution_name"
            class="w-100 mx-1 mb-3"
            :name="$t('name')"
            :placeholder="$t('name')"
            :error="errors['devolution_name']"
            @valid="updateValidFields"
            v-model="newCompany.devolution_name"
          />
          <div
            :class="
              !newCompany.use_seller_devolution_address
                ? 'anim-fade-one'
                : 'anim-fade-disappear'
            "
            class="w-100 d-flex justify-content-between flex-wrap"
          >
            <NbSelectInput
              :required="true"
              id="devolution_country"
              class="w-32 mx-1 mb-3"
              :name="$t('country')"
              :placeholder="$t('country')"
              :options="countriesAll"
              :variant="
                newCompany.use_seller_devolution_address ? 'borderless' : ''
              "
              :disabled="newCompany.use_seller_devolution_address"
              :error="errors['devolution_country']"
              @valid="updateValidFields"
              v-model="newCompany.devolution_country"
            />
            <NbTextInput
              :required="true"
              id="devolution_state"
              class="w-32 mx-1 mb-3"
              :variant="
                newCompany.use_seller_devolution_address ? 'borderless' : ''
              "
              :disabled="newCompany.use_seller_devolution_address"
              :name="$t('state')"
              :placeholder="$t('state')"
              :error="errors['devolution_state']"
              @valid="updateValidFields"
              v-model="newCompany.devolution_state"
            />
            <NbTextInput
              :required="true"
              id="devolution_postal_code"
              type="number"
              class="w-32 mx-1 mb-3"
              :variant="
                newCompany.use_seller_devolution_address ? 'borderless' : ''
              "
              :disabled="newCompany.use_seller_devolution_address"
              :name="$t('postalCode')"
              placeholder="12345-678"
              :error="errors['devolution_postal_code']"
              @valid="updateValidFields"
              v-model="newCompany.devolution_postal_code"
            />
            <NbTextInput
              :required="true"
              id="address"
              class="w-32 mx-1 mb-3"
              :variant="
                newCompany.use_seller_devolution_address ? 'borderless' : ''
              "
              :disabled="newCompany.use_seller_devolution_address"
              :name="$t('address')"
              :placeholder="$t('address')"
              :error="errors['address']"
              @valid="updateValidFields"
              v-model="newCompany.devolution_address"
            />
            <NbTextInput
              :required="true"
              id="devolution_city"
              class="w-32 mx-1 mb-3"
              :variant="
                newCompany.use_seller_devolution_address ? 'borderless' : ''
              "
              :disabled="newCompany.use_seller_devolution_address"
              :name="$t('city')"
              :placeholder="$t('city')"
              :error="errors['devolution_city']"
              @valid="updateValidFields"
              v-model="newCompany.devolution_city"
            />
            <NbTextInput
              :required="true"
              id="devolution_address_number"
              class="w-32 mx-1 mb-3"
              :variant="
                newCompany.use_seller_devolution_address ? 'borderless' : ''
              "
              :disabled="newCompany.use_seller_devolution_address"
              :name="$t('number')"
              :placeholder="$t('number')"
              :error="errors['devolution_address_number']"
              @valid="updateValidFields"
              v-model="newCompany.devolution_address_number"
            />
            <NbTextInput
              :required="true"
              id="devolution_address_complement"
              class="w-100 mx-1 mb-3"
              :variant="
                newCompany.use_seller_devolution_address ? 'borderless' : ''
              "
              :disabled="newCompany.use_seller_devolution_address"
              :name="$t('companyPage.addressComplement')"
              :placeholder="$t('companyPage.addressComplement')"
              :error="errors['devolution_address_complement']"
              @valid="updateValidFields"
              v-model="newCompany.devolution_address_complement"
            />
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <NbButton
            class="mt-2 mr-3"
            variant="secondary"
            size="sm"
            @click="saveChanges"
          >
            {{ $t("update") }}
          </NbButton>
        </div>
      </template>
    </NbCard>
  </div>
</template>

<script>
import NProgress from "nprogress";
import NbCard from "@/components/cards/NbCard.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components//input/select/NbSelectInput.vue";
import NbSwitch from "@/components/buttons/NbSwitch.vue";
import NbButton from "@/components/buttons/NbButton.vue";

/* services */
import SellerService from "@/services/SellerService";
import CarrierService from "@/services/CarrierService";
import UserService from "@/services/UserService";
import CountryService from "@/services/CountryService";
import AddressAutocompleteService from "@/services/AddressAutocompleteService";
import PrintingService from "@/services/PrintingService";

const printingService = new PrintingService();
const userService = new UserService();

export default {
  name: "GeneralData",
  components: {
    NbCard,
    NbTextInput,
    NbSelectInput,
    NbSwitch,
    NbButton,
  },
  props: {},
  data: () => {
    return {
      sellerService: new SellerService(),
      carrierService: new CarrierService(),
      userService: new UserService(),
      countryService: new CountryService(),
      addressAutocompleteService: new AddressAutocompleteService(),
      errors: {},
      company: {},
      user: userService.getLoggedInUser(),
      newCompany: {
        auth_signature: null,
        name: "",
        email: "",
        site: "",
        phone: "",
        tax_number: "",
        country: "",
        state: "",
        zip_code: "",
        address: "",
        city: "",
        address_number: "",
        address_complement: "",

        devolution_country: "",
        devolution_state: "",
        devolution_postal_code: "",
        devolution_address: "",
        devolution_city: "",
        devolution_address_number: "",
        devolution_address_complement: "",
        use_seller_devolution_address: true,
      },
      saveFormDelayed: setTimeout(() => null),
      auth_signature: null,
      url_logo: "",
      signature_url: "",
      countriesAll: [],
    };
  },
  created() {
    this.getCompany();
    this.getCountriesAll();
  },
  methods: {
    async getCompany() {
      if (this.user.profile === "seller") {
        try {
          const response = await this.sellerService.getCurrentSeller();
          const company = response.data.data;

          if (company.company_logo) {
            this.url_logo = company.logo_url;
          }

          if (company.signature_url) {
            this.signature_url = company.signature_url;
          }

          this.company = company;

          this.newCompany = Object.assign({}, this.company);

          //this.setDevolutionAddres();
        } finally {
          NProgress.done();
        }
      } else if (this.user.profile === "carrier") {
        this.carrierService.getCurrentCarrier().then((response) => {
          const company = response.data.data;
          this.company = company;
        });
      } else if (this.user.profile === "nobordist") {
        const company = {
          name: "Nobordist",
          site: "nobordist.com",
          email: "contact@nobordist.com",
          address: "Avenida Brigadeiro Faria Lima",
          address_number: "1912",
          zip_code: "01451-000",
          city: "São Paulo",
          state: "SP",
          country: "Brasil",
        };
        this.company = company;
      }
    },
    async getCountriesAll() {
      const { data: countries } = await this.countryService.getCountries();
      this.countriesAll = countries.data.map(({ alpha2_code, name }) => ({
        value: alpha2_code,
        text: name,
      }));
    },
    saveChanges() {
      clearInterval(this.saveFormDelayed);
      this.saveFormDelayed = setTimeout(() => this.updateCompany(), 1500);
    },
    async updateCompany() {
      try {
        this.$root.$emit("isSaving", true);
        let newCompanyInfo = {};
        for (var info in this.newCompany) {
          if (
            ![
              "updated_at",
              "created_at",
              "ad",
              "logo_url",
              "signature_url",
            ].includes(info)
          ) {
            newCompanyInfo[info] = this.newCompany[info];
          }
        }
        if (this.user.profile === "seller") {
          const response = await this.sellerService.updateSeller(
            this.company.id,
            newCompanyInfo
          );
          this.newCompany = response.data.data;
          if (response.data.messages[0] == "devolution_warning") {
            this.newCompany.use_seller_devolution_address = false;
            this.$helpers.toast(response.data.messages[1][0], "warning");
          } /*  else {
            this.$helpers.toast(response.data.messages[0], 'success')
          } */
        }
      } catch (error) {
        if (
          Object.prototype.hasOwnProperty.call(error, "response") &&
          Object.prototype.hasOwnProperty.call(error.response, "data") &&
          Object.prototype.hasOwnProperty.call(error.response.data, "messages")
        ) {
          this.errors = error.response.data.messages[0];
        }
        NProgress.done();
      } finally {
        this.$root.$emit("isSaving", false);
      }
    },
    updateValidFields(validFieldId) {
      this.errors[validFieldId] = ""; //atualizar no front
      delete this.errors[validFieldId];
    },

    setDevolutionAddres() {
      if (this.newCompany.use_seller_devolution_address) {
        this.newCompany.devolution_country = this.newCompany.country;
        this.newCompany.devolution_state = this.newCompany.state;
        this.newCompany.devolution_postal_code = this.newCompany.zip_code;
        this.newCompany.devolution_address = this.newCompany.address;
        this.newCompany.devolution_city = this.newCompany.city;
        this.newCompany.devolution_address_number =
          this.newCompany.address_number;
        this.newCompany.devolution_address_complement =
          this.newCompany.address_complement;
      } else {
        const devolutionKeys = Object.keys(this.company).filter((key) =>
          key.includes("devolution")
        );

        for (const key of devolutionKeys) {
          if (key !== "use_seller_devolution_address") {
            this.newCompany[key] = this.company[key];
          }
        }
      }
    },

    updateDevolutionAddressSwitch() {
      //this.setDevolutionAddres();

      printingService
        .updatePrinting({
          seller: {
            id: this.company.id,
            use_seller_devolution_address:
              this.newCompany.use_seller_devolution_address,
          },
        }) /* .then(() => {
        this.$helpers.toast('Devolution Address Option Updated', 'success')
      }) */
        .catch((error) => {
          if (error.response.data.messages[0]) {
            this.newCompany.use_seller_devolution_address = false;
            this.$helpers.toast(error.response.data.messages[0], "warning");
          }
        });
    },
  },
};
</script>

<style scoped></style>
