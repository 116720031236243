<template>
  <div>
    <LoadingPage v-show="loaded" />
    <div v-show="!loaded">
      <NbPageTitle
        :title="$t('companyPage.title')"
        :subTitle="$t('companyPage.subTitle')"
      >
        <!-- CARD HERE? -->
      </NbPageTitle>

      <section class="w-100 pl-3">
        <NbTabs
          class="mb-4"
          :tabs="tabsOptions"
          @switchTab="switchTab($event)"
        />

        <GeneralData v-show="currentTable == 'generalData'" />

        <BrandSignature v-show="currentTable == 'brandSignature'" />
      </section>

      <NbFooter
        :text="$t('components.footer.moreAboutCompany')"
        link="https://nobordistinc.freshdesk.com/support/home"
      />
    </div>
  </div>
</template>

<script>
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import GeneralData from "@/components/account/GeneralData.vue";
import BrandSignature from "@/components/account/BrandSignature.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";

export default {
  name: "account-company",
  components: {
    LoadingPage,
    NbPageTitle,
    NbTabs,
    BrandSignature,
    GeneralData,
    NbFooter,
  },
  data: function () {
    return {
      loaded: true,
      currentTable: "",
    };
  },
  beforeMount() {
    setTimeout(() => {
      this.loaded = false;
    }, 1000);
    this.currentTable = "generalData";
  },

  methods: {
    switchTab(tab) {
      this.currentTable = tab;
    },
  },
  computed: {
    tabsOptions() {
      return [
        {
          label: this.$t("companyPage.generalData"),
          current: this.currentTable,
          id: "generalData",
        },
        {
          label: this.$t("companyPage.brandSignature"),
          current: this.currentTable,
          id: "brandSignature",
        },
      ];
    },
  },
};
</script>
<style lang="scss"></style>
