import $ from "jquery";

export default class AddressAutocompleteService {
  getCep(zip) {
    const cep = zip.replace(/\D/g, "");
    return Promise.resolve(
      $.ajax({
        type: "GET",
        url: "https://viacep.com.br/ws/" + cep + "/json/?callback=?",
        data: "",
        dataType: "json",
      })
    );
  }
}
