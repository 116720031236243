<template>
  <div class="brand-signature-wrapper">
    <NbCard id="visual-card" class="mb-2">
      <div class="heading-3">
        {{ $t("companyPage.brandSignaturePage.visualTitle") }}
      </div>
      <div class="body-3 mb-4">
        {{ $t("companyPage.brandSignaturePage.visualSubTitle") }}
      </div>

      <div class="d-flex justify-content-between">
        <div class="w-48-5 d-flex justify-content-start">
          <NbDropFile
            class="w-100"
            :imgShow="url_logo"
            :accept="['application/png', 'application/jpg', 'application/jpeg']"
            imgHeight="8rem"
            style="max-width: 25rem"
            v-model="file_logo"
          >
            <div v-if="!signature_file" class="mt-3 text-center">
              <div class="body-2">
                {{ $t("companyPage.brandSignaturePage.noImageAdded") }}
              </div>
              <div class="link-1 text-center">
                {{ $t("companyPage.brandSignaturePage.clickOrDrag") }}
              </div>
            </div>
          </NbDropFile>
          <div class="ml-3">
            <div class="heading-2 mb-2">
              {{ $t("companyPage.brandSignaturePage.companyBrand") }}
            </div>
            <div class="body-3 mb-3">
              {{ $t("companyPage.brandSignaturePage.recomendedWidth") }}
            </div>
            <div>
              <NbButton
                variant="secondary"
                size="sm"
                @click="updateVisualIdentity"
              >
                {{ $t("upload") }}
              </NbButton>
            </div>
          </div>
        </div>
        <div class="w-48-5">
          <div>
            <div class="heading-2 mb-2">
              {{ $t("companyPage.brandSignaturePage.companyColors") }}
            </div>
            <div class="body-3 mb-3">
              {{ $t("companyPage.brandSignaturePage.reflectOn") }}
              <a href="notifications/tracking" target="_blank" class="link-1">
                {{ $t("components.nav.TrackingPage") }} >
              </a>
            </div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-32-5 mr-2">
              <NbPickColor
                :required="true"
                :label="$t('companyPage.brandSignaturePage.foregroundColor')"
                v-model="seller_settings.primary_color"
              />
            </div>
            <div class="w-32-5 mr-2">
              <NbPickColor
                :required="true"
                :label="$t('companyPage.brandSignaturePage.backgroundColor')"
                v-model="seller_settings.secondary_color"
              />
            </div>
            <div class="w-32-5">
              <NbPickColor
                :required="true"
                :label="$t('companyPage.brandSignaturePage.textColor')"
                v-model="seller_settings.text_color"
              />
            </div>
          </div>
          <div class="mt-4 d-flex justify-content-between">
            <div class="mt-1">
              {{ $t("companyPage.brandSignaturePage.returnTo") }}
              <span class="link-1" @click="setDefaultColors">
                {{ $t("companyPage.brandSignaturePage.defaultColors") }}
              </span>
            </div>
            <NbButton
              variant="secondary"
              size="sm"
              @click="updateSellerSettings"
            >
              {{ $t("save") }}
            </NbButton>
          </div>
        </div>
      </div>
    </NbCard>

    <NbCard id="signature-card" class="mb-2">
      <div class="heading-3">
        {{ $t("companyPage.brandSignaturePage.signatureTitle") }}
      </div>
      <div class="body-3 mb-4">
        {{ $t("companyPage.brandSignaturePage.signatureSubTitle") }}
      </div>

      <div class="d-flex justify-content-between">
        <div class="w-48-5 d-flex justify-content-start">
          <NbDropFile
            class="w-100"
            :imgShow="signature_url"
            :accept="['application/png', 'application/jpg', 'application/jpeg']"
            imgHeight="8rem"
            style="max-width: 25rem"
            v-model="signature_file"
          >
            <div v-if="!file_logo" class="mt-3 text-center">
              <div class="body-2">
                {{ $t("companyPage.brandSignaturePage.noImageAdded") }}
              </div>
              <div class="link-1 text-center">
                {{ $t("companyPage.brandSignaturePage.clickOrDrag") }}
              </div>
            </div>
          </NbDropFile>
          <div class="ml-3">
            <div class="heading-2 mb-2">
              {{ $t("companyPage.brandSignaturePage.companySignature") }}
            </div>
            <div class="body-3 mb-3">
              {{ $t("companyPage.brandSignaturePage.recomendedWidth") }}
            </div>
            <div>
              <NbButton variant="secondary" size="sm" @click="updateSignature">
                {{ $t("upload") }}
              </NbButton>
            </div>
          </div>
        </div>
        <div class="w-48-5">
          <NbTextInput
            required
            id="legal_name"
            :name="$t('companyPage.legalName')"
            class="mb-3"
            v-model="seller.legal_name"
          />
          <NbSwitch
            v-model="seller.auth_signature"
            id="auth_signature"
            :name="$t('companyPage.brandSignaturePage.authUse')"
          >
            {{ $t("companyPage.authorizeText") }}
          </NbSwitch>
          <NbButton
            class="float-right mt-4"
            variant="secondary"
            size="sm"
            @click="updateSeller"
          >
            {{ $t("save") }}
          </NbButton>
        </div>
      </div>
    </NbCard>
  </div>
</template>

<script>
import NbCard from "@/components/cards/NbCard.vue";
import NbPickColor from "@/components/input/pick-color/NbPickColor.vue";
import NbDropFile from "@/components/input/drop-file/NbDropFile.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import SellerService from "@/services/SellerService";
import UserService from "@/services/UserService";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSwitch from "@/components/buttons/NbSwitch.vue";

const userService = new UserService();

export default {
  name: "BrandSignature",
  components: {
    NbCard,
    NbDropFile,
    NbPickColor,
    NbButton,
    NbTextInput,
    NbSwitch,
  },
  props: {},
  data: () => {
    return {
      user: userService.getLoggedInUser(),
      sellerService: new SellerService(),
      seller: null,
      seller_settings: {
        primary_color: "#006464",
        secondary_color: "#f2f2f2",
        text_color: "#ffffff",
        auth_signature: null,
      },
      default_colors: {
        primary_color: "#006464",
        secondary_color: "#f2f2f2",
        text_color: "#ffffff",
        legal_name: "",
        auth_signature: false,
      },
      signature_url: "",
      signature_file: "",
      url_logo: "",
      file_logo: "",
    };
  },
  created() {
    this.getSellerSettings();
  },
  methods: {
    setDefaultColors() {
      this.seller_settings.primary_color = this.default_colors.primary_color;
      this.seller_settings.secondary_color =
        this.default_colors.secondary_color;
      this.seller_settings.text_color = this.default_colors.text_color;
    },
    async getSellerSettings() {
      const { data: currentSeller } =
        await this.sellerService.getCurrentSeller();
      this.seller = currentSeller.data;
      const { data: sellerSettings } =
        await this.sellerService.getSellerSettings(this.seller.id);
      this.seller_settings = sellerSettings.data;

      if (this.seller.signature_url) {
        this.signature_url = this.seller.signature_url;
      }
      if (this.seller.company_logo) {
        this.url_logo = this.seller.logo_url;
      }
      if (!this.seller_settings.primary_color) {
        this.seller_settings.primary_color = "#006464";
      }
      if (!this.seller_settings.secondary_color) {
        this.seller_settings.secondary_color = "#F2F2F2";
      }
      if (!this.seller_settings.text_color) {
        this.seller_settings.text_color = "#ffffff";
      }
    },

    async saveSignature(company_signature) {
      try {
        await this.sellerService.saveSignature(
          this.seller.id,
          company_signature
        );
      } catch (error) {
        this.$helpers.toast(
          error.response.data.messages[0] || error.message || "Request failed",
          "danger"
        );
      } finally {
        this.signature_file = "";
      }
    },
    updateSignature() {
      const company_signature = this.signature_file;
      this.signature_url = URL.createObjectURL(company_signature);
      this.saveSignature(company_signature);
    },

    async saveLogo(company_logo) {
      try {
        await this.sellerService.saveImage(this.seller.id, company_logo);
      } catch (error) {
        this.$helpers.toast(
          error.message || error.msg || "Request failed",
          "danger"
        );
      } finally {
        this.file_logo = "";
      }
    },
    updateVisualIdentity() {
      const company_logo = this.file_logo;
      this.url_logo = URL.createObjectURL(company_logo);
      this.saveLogo(company_logo);
    },
    async updateSellerSettings() {
      try {
        await this.sellerService.updateSellerSettings(
          this.seller_settings.id,
          this.seller_settings
        );
      } catch (error) {
        if (
          error.response &&
          error.responsa.data &&
          error.response.data.messages &&
          error.response.data.messages[0]
        ) {
          this.$helpers.toast(
            error.messages || error.msg || "Request failed",
            "danger"
          );
        }
      }
    },
    async updateSeller() {
      try {
        await this.sellerService.updateSeller(this.seller.id, this.seller);
      } catch (error) {
        if (
          error.response &&
          error.responsa.data &&
          error.response.data.messages &&
          error.response.data.messages[0]
        ) {
          this.$helpers.toast(
            error.messages || error.msg || "Request failed",
            "danger"
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.logo-previsualization {
  object-fit: contain;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>
