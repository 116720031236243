<template>
  <div>
    <label v-show="label" class="input-label">{{ configuredName }}</label>
    <div class="pick-color" @click="chooseColor">
      <span class="hexadecimal-color">{{ modelValue }}</span>

      <input
        v-model="modelValue"
        ref="pickColor"
        type="color"
        @change="onChange"
        class="pick"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "NbPickColor",
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      modelValue: this.value,
    };
  },
  watch: {
    value(val) {
      this.modelValue = val;
    },
  },
  computed: {
    configuredName() {
      if (this.required) {
        return `${this.label} *`;
      }
      return this.label;
    },
  },
  methods: {
    chooseColor() {
      this.$refs.pickColor?.click();
    },
    onChange(event) {
      this.$emit("input", event.target.value);
    },
  },
  mounted() {
    if (!this.value) {
      this.modelValue = this.$refs.pickColor.value;
      this.$emit("input", this.modelValue);
    }
  },
};
</script>

<style lang="scss" scoped>
.pick-color {
  background-color: var(--gray-05);
  padding: 0.5rem 0.75rem;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid var(--black);
  transition: all 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 12px;
  &:hover {
    background-color: var(--gray-10);
  }

  .hexadecimal-color {
    flex: 1;
    border-right: 1px solid var(--gray-10);
  }
}

.pick {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  width: 2rem;
  height: 2rem;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
.pick::-webkit-color-swatch {
  border-radius: 50%;
  border-color: var(--gray-20);
}
.pick::-moz-color-swatch {
  border-radius: 50%;
  border-color: var(--gray-20);
}
</style>
