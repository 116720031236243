import HttpService from "./HttpService";

export default class CarrierService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get carriers
   *
   * @returns {Promise}
   */
  getCarriers() {
    return this.httpService.get("/v1/carriers");
  }

  getCurrentCarrier() {
    return this.httpService.get("/v1/current-carrier");
  }

  updateCarrier(id, data) {
    return this.httpService.put("/v1/current-carrier/" + id, data);
  }
}
